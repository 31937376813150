import { defineStore } from 'pinia';
import { player } from '../types';

export const usePlayerStore = defineStore({
  id: 'player',
  state: () => ({
    mainPlayer: {} as player | undefined,
    ordinale: undefined as number | undefined,
    otherPlayers: [] as player[],
    team: undefined as string | undefined,
    teamId: undefined as string | undefined,
    isName: true,
    isAvatarEnabled: undefined as boolean | undefined,
  }),
  actions: {
    setPlayer(player: player) {
      if (player?.node?.DeviceID?.objectId == localStorage.getItem('activeDevice')) {
        this.mainPlayer = player;
        this.ordinale = player?.node?.Ordinale;
      } else this.otherPlayers.push(player);
    },
    setTeam(team: string) {
      this.team = team;
    },
    setEnableAvatar(isEnabled: boolean) {
      this.isAvatarEnabled = isEnabled;
    },
    setTeamId(teamId: string) {
      this.teamId = teamId;
    },
    resetPlayers(){
      this.mainPlayer = {} as player,
      this.otherPlayers = []
    }
  },
});
//str.slice(0,a) + 1 + str.slice(a + 1, str.length)
