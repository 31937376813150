import { defineStore } from 'pinia';

export const useDeviceModalStore = defineStore({
  id: 'deviceModal',
  state: () => ({
    isOpen: false,
    devicesList: [],
    pswd: true,
  }),
  actions: {
    close() {
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
    },
    addDevices(newList: []) {
      this.devicesList = newList;
    },
    
  },
});
