<template>
  <div>
<!--  <div style="width: 1133px; height: 720px; border: 5px solid lightgrey; background-color: blue" >-->
<!--    <Header />-->
    <DeviceModal v-if="devicesStore.isOpen" />
    <main class="page-content">
      <router-view />
    </main>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
// eslint-disable-next-line import/no-extraneous-dependencies
import { onMounted } from 'vue';
import Header from '@/components/header.vue';
import DeviceModal from '@/components/deviceModal.vue';
import { useDeviceModalStore } from './store/deviceModal';
import { useAuthStore } from './store/auth';

export default {
  components: {
    Header,
    DeviceModal,
  },
  setup() {
    const devicesStore = useDeviceModalStore();
    const auth = useAuthStore();
    onMounted(() => {
      if (!localStorage.getItem('activeDevice') && auth.user) {
        devicesStore.open();
      }
    });
    /*       });
    }); */
    return {
      devicesStore,
    };
  },
};
</script>
<style>
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {

  font-size: 15px;
  --primary-color: rgb(75, 255, 255);
  --secondary-color: rgb(164, 117, 255);
  --tertiary-color: rgb(250, 178, 30);
  --title-font-size: 1.5rem;
  --subtitle-font-size: 1.25rem;
  --description-font-size: 1rem;
  --overlay-background-color: rgba(0, 0, 0, 0.77);

}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100vh;
  /*background: url("@/assets/EscapeRoom_DigiUnit_sfondo-commissioning.jpg") no-repeat center; !* #var *!*/
  /*background-size: cover !important;*/
  /*background-color: orange;*/
  position: relative;
}

main {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.flex {
  display: flex;
}

p {
  margin: 0;
  font-size: 18px;
}

/* GLOBALI */
.main-container {
  position: relative;
  margin: 2.5em;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  /*padding: 4em;*/
  /*background-color: white;*/
}

.main-front {
  background-color: #000000; /* #var */
  border: 2px solid var(--primary-color); /* #var */
  color: white; /* #var */
  font-family: 'Space Mono', monospace;

  /*margin: 2.5em;*/
  /*margin-left: auto;*/
  /*margin-right: auto;*/
  padding: 3em;
  z-index: 1;
  position: relative;

  /*position: absolute;*/
  /*height: 100%;*/
  /*width: 100%;*/
  /*z-index: 0;*/
  /*top: 0;*/
  /*left: 0;*/
}

.title {
  text-transform: uppercase;
  font-family: 'Press Start 2P', cursive;
  color: var(--primary-color);
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 10px;
}

.subtitle {
  color: var(--secondary-color);
  font-family: 'Space Mono', monospace;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.description {
  font-size: 1rem;
  line-height: 1.5rem;
}

.main-back {
  background-color: transparent;
  border: 2px solid var(--secondary-color);
  height: 100%;
  width: 100%;

  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 0;
}

.error-message-container {
  min-height: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.error-message {
  color: var(--tertiary-color) !important;
  font-size: 1.5rem;
  padding-top: .5rem;
}

.next {
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 0.5em;
  padding-left: 2em;
  padding-right: 2em;
  border-radius: 25px;
  margin-bottom: 2em;
  cursor: pointer;
}
.next {
  text-transform: uppercase;
  background-color: var(--secondary-color);
  box-shadow: 10px 10px 0 var(--primary-color);
  border-radius: 0 !important;
  border: none;
  font-family: 'Press Start 2P', cursive;
}

body{
  background: url("@/assets/EscapeRoom_DigiUnit_sfondo-commissioning.jpg") no-repeat center;
}
</style>
