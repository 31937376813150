import { defineStore } from 'pinia';
import { stanza, domanda, risposta } from '../types';
export const useStanzeStore = defineStore({
  id: 'stanze',
  state: () => ({
    stanze: [] as stanza[],
    activeStanza: 0,
    activeDomanda: 0,
    activeRisposta: undefined as undefined | any,
    altro: false as boolean
  }),
  actions: {
    addStanza(stanza: any) {
      this.stanze.push(stanza);
    },
    addDomandaChiusa(domanda: domanda, StanzaID: string, risposte: risposta[], OrdinaleInStanza: number) {
      let newDomanda = domanda;
      newDomanda.OrdinaleInStanza = OrdinaleInStanza
      newDomanda.risposte = risposte;
      for (let i = 0; i < this.stanze.length; i++) {
        if (this.stanze[i].StanzaID.objectId === StanzaID) {
          if (this.stanze[i].domande) {
            this.stanze[i].domande.push(newDomanda);
          } else {
            this.stanze[i].domande = [newDomanda];
          }
        }
      }
    },
    addDomandaAperta(domanda: domanda, StanzaID: string, OrdinaleInStanza: number) {
      domanda.OrdinaleInStanza = OrdinaleInStanza
      for (let i = 0; i < this.stanze.length; i++) {
        if (this.stanze[i].StanzaID.objectId === StanzaID) {
          if (this.stanze[i].domande) {
            this.stanze[i].domande.push(domanda);
          } else {
            this.stanze[i].domande = [domanda];
          }
        }
      }
    },
    orderQuestions(StanzaID: string) {
      for (let i = 0; i < this.stanze.length; i++) {
        if (this.stanze[i].StanzaID.objectId === StanzaID) {
          this.stanze[i].domande.sort((a,b)=>{
            if(a.OrdinaleInStanza < b.OrdinaleInStanza) return -1
            if(a.OrdinaleInStanza > b.OrdinaleInStanza) return 1
            return 0
          })
        } 
      }
      
    },
    nextRoom() {
      this.activeDomanda = 0;
      this.activeStanza++;
    },
    nextDomanda() {
      this.activeDomanda++;
    },
  },
});
