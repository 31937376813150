import Parse from 'parse';
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createPinia } from 'pinia';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const pinia = createPinia();

Parse.initialize(process.env.VUE_APP_APPLICATION_ID!, process.env.VUE_APP_JS_KEY);
Parse.serverURL = 'https://parseapi.back4app.com/';

createApp(App)
  .use(router)
  .use(VueLoading, {
    color: '#009EF7',
    isFullPage: true,
  })
  .use(pinia)
  .mount('#app');
