import { defineStore } from 'pinia';
export const useAvatarStore = defineStore({
  id: 'avatar',
  state: () => ({
    avatar: undefined as object | undefined,
    img: undefined as string | undefined,
  }),
  actions: {
    updateAvatar(avatar: any) {
      this.avatar = avatar;
    },
    updateImg(img: any) {
      this.img = img;
    },
  },
});
