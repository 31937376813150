import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: '',
  }),
  actions: {
    login(user: string) {
      this.user = user;
    },
    logout() {
      this.user = '';
    },
  },
});
