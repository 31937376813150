import { defineStore } from 'pinia';

export const useSessionStore = defineStore({
  id: 'session',
  state: () => ({
    isActive: false,
    loggedIn: false,
    name: undefined as undefined | string,
    id: undefined as undefined | string,
    faseId: undefined as undefined | string,
    identity: undefined as undefined | string,
    adventureId: undefined as undefined | string,
    feedbackInterval: undefined as any,
    isFinishedInterval: undefined as any
  }),
  actions: {
    close() {
      this.isActive = false;
    },
    open() {
      this.isActive = true;
    },
    setName(name: string) {
      this.name = name;
    },
    setId(id: string) {
      this.id = id;
    },
    setFaseId(id: string) {
      this.faseId = id;
    },
    setAdventureId(id: string) {
      this.adventureId = id;
    },
    setIdentity(identity: string) {
      this.identity = identity;
    },
  },
});
