import Parse from 'parse';
import { useLoading } from 'vue-loading-overlay';

export default function back4App() {
  /* const $loading = useLoading(); */

  function get(name: string, data = {}) {
    /* const loader = $loading.show(); */
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      })
      .finally(/* () => loader.hide() */);
  }

  function post(name: string, data: Object) {
    /* const loader = $loading.show(); */
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      });
    /* .finally(() => loader.hide()); */
  }

  function put(name: string, data: Object) {
    /* const loader = $loading.show(); */
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      });
    /* .finally(() => loader.hide()); */
  }

  function remove(name: string, data: Object) {
    /* const loader = $loading.show(); */
    return Parse.Cloud.run(name, data)
      .then((res) => res.data)
      .catch(() => {
        //console.clear();
      });
    /* .finally(() => loader.hide()); */
  }

  return {
    get,
    post,
    put,
    remove,
  };
}
